import bw1 from '../engagementImages/bw-1.jpg';
import bw2 from '../engagementImages/bw-2.jpg';

const Album = [
    {
        image: bw1,
    },
    {
        image: bw2,
    },
    {
        image: bw1,
    },
    {
        image: bw2,
    },
    {
        image: bw1,
    },
    {
        image: bw2,
    },
    {
        image: bw1,
    },
    {
        image: bw2,
    },
    {
        image: bw1,
    },
    {
        image: bw2,
    },
    {
        image: bw1,
    },
    {
        image: bw2,
    },
    {
        image: bw1,
    },
    {
        image: bw2,
    },
]

export default Album